import moment from "moment"
import { httpClient } from "@/libs/http"
import { checkUserLoginPermission, checkUserLoginModule } from "@/libs/helpers";
import { NUMBER_TO_TEXT } from "@/libs/const";

export default {
    computed: {
        globalMoneyOptions() {
            return {
                length: 11,
                precision: 0
            }
        }
    },
    methods: {
        capitalizeStr(str) {
            if (!str) {
                return str
            }
            const arr = str.split(" ");
            for (let i = 0; i < arr.length; i++) {
                arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
            }
            return arr.join(" ");
        },

        encodeNumber(number) {
            const digits = number.toString().split("");
            const encodedDigits = digits.map(digit => NUMBER_TO_TEXT[digit]);
            return encodedDigits.join("-");
        },

        decodeNumber(text) {
            const encodedDigits = text.split("-");
            const decodedDigits = encodedDigits.map(encodedDigit => {
                return Object.keys(NUMBER_TO_TEXT).find(key => NUMBER_TO_TEXT[key] === encodedDigit) || 'ERR'
            });
            return Number(decodedDigits.join(""));
        },

        generateRandomString(length = 10) {
            let result = ''
            let characters = 'ABCDEFGHJKMNPQRSTUVWXYZ0123456789'
            let charactersLength = characters.length
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength))
            }
            return result
        },

        debounce(fn, delay) {
            let timeoutID = null;
            return function () {
                clearTimeout(timeoutID)
                let args = arguments
                let that = this
                timeoutID = setTimeout(function () {
                    fn.apply(that, args);
                }, delay)
            }
        },

        copyText(text) {
            const el = document.createElement("textarea");
            el.value = text;
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
        },

        copyTextHasBr(element) {
            const $temp = document.createElement("textarea");
            const brRegex = /<br\s*[/]?>/gi;
            document.body.appendChild($temp);
            $temp.val(element.innerHTML.replace(brRegex, "\r\n")).select();
            document.execCommand("copy");
            $temp.remove();
        },

        subTimeHuman(time) {
            const now = moment().unix()
            const checkTime = moment(time).unix()
            const seconds = now - checkTime

            const d = Math.floor(seconds / (3600 * 24));
            const h = Math.floor(seconds % (3600 * 24) / 3600);
            const m = Math.floor(seconds % 3600 / 60);
            const s = Math.floor(seconds % 60);

            const times = []
            if (d > 0) {
                times.push(`${d}d,`)
            }
            if (h > 0) {
                times.push(`${h}h`)
            }
            if (m > 0) {
                times.push(`${m}m`)
            }
            times.push(`${s}s`)
            return times.join(' ')
        },

        secondTimeHuman(seconds) {
            const d = Math.floor(seconds / (3600 * 24));
            const h = Math.floor(seconds % (3600 * 24) / 3600);
            const m = Math.floor(seconds % 3600 / 60);
            const s = Math.floor(seconds % 60);

            const times = []
            if (d > 0) {
                times.push(`${d}d,`)
            }
            if (h > 0) {
                times.push(`${h}h`)
            }
            if (m > 0) {
                times.push(`${m}m`)
            }
            times.push(`${s}s`)
            return times.join(' ')
        },

        formatDateTime(time, format = 'HH:mm DD/MM/YYYY') {
            return moment(time).format(format)
        },

        formatNumber(numb, fix = 0) {
            if (!numb) numb = 0
            let fixPow = Math.pow(10, fix);
            numb = Math.round(numb * fixPow) / fixPow;
            return numb.toString().replace(/\d(?=(\d{3})+(?!\d))/g, '$&,');
        },

        isPhoneValid(phone) {
            // return /^(09|08|03|07|05|04)(\d{8})$/.test(phone) || /^(84)(\d{9})$/.test(phone)
            if (!phone) return false;
            if (!phone.trim()) return false;
            const re = /^(0[2-9][0-9]{8,9})$/;
            return re.test(phone);
        },

        isEmailValid(email) {
            return /\S+@\S+\.\S+/.test(email)
        },

        isUsernameValid(username) {
            return /^[a-z0-9_.]+$/.test(username) || /\S+@\S+\.\S+/.test(username)
        },

        isNumber(str) {
            return !isNaN(str);
        },

        checkMenuMobile(isCheck) {
            return !isCheck || (isCheck && this.isMobileAgent())
        },
        checkMenuDesktop(isCheck) {
            return !isCheck || (isCheck && !this.isMobileAgent())
        },

        isMobileAgent() {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
        },

        isInputNumber(e) {
            e = (e) ? e : window.event;
            let charCode = (e.which) ? e.which : e.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 190) {
                e.preventDefault()
                e.stopPropagation()
                return false;
            } else {
                return true;
            }
        },

        isInputInteger(e) {
            e = (e) ? e : window.event;
            let charCode = (e.which) ? e.which : e.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
                e.preventDefault()
                e.stopPropagation()
                return false;
            } else {
                return true;
            }
        },

        inputMaxlength(e) {
            if (e.target.value.length >= e.target.maxLength) {
                e.preventDefault()
                e.stopPropagation()
                return false
            }
            return true
        },

        getUrlParameter(sParam) {
            let sPageURL = window.location.search.substring(1),
                sURLVariables = sPageURL.split('&'),
                sParameterName,
                i;
            for (i = 0; i < sURLVariables.length; i++) {
                sParameterName = sURLVariables[i].split('=')
                if (sParameterName[0] === sParam) {
                    return typeof sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1])
                }
            }
            return false
        },

        checkPermission(permissions) {
            const userPermission = window.me && window.me.permissions || []
            return checkUserLoginPermission(userPermission, permissions)
        },

        checkModule(modules) {
            const userModule = window.me && window.me.modules || []
            return checkUserLoginModule(userModule, modules)
        },

        async downloadExcelFile(url, data, filename = 'default.xlsx') {
            try {
                const res = await httpClient.post(url, data, {
                    responseType: 'blob'
                })
                const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = filename;
                link.click();
                URL.revokeObjectURL(link.href);
            } catch (e) {
                console.log('downloadExcelFile->ERROR: ', e)
                this.$vToastify.error('Có lỗi xảy ra nên chưa thể tải file');
            }
        },

        downloadPdfFile(url, filename = 'default.pdf') {
            try {
                const link = document.createElement('a');
                link.href = url;
                link.download = filename;
                link.click();
                URL.revokeObjectURL(link.href);
            } catch (e) {
                console.log('downloadPdfFile->ERROR: ', e)
                this.$vToastify.error('Có lỗi xảy ra nên chưa thể tải file');
            }
        },

        replaceTxtStar(txt) {
            if (!txt || txt.length === 0) {
                return ''
            }
            if (!txt.includes('*')) {
                return txt
            }
            const txtSpl = txt.split('')
            const newTxt = []
            for (let i = 0; i < txtSpl.length; i++) {
                const t = txtSpl[i]
                const t_1 = txtSpl[i - 1]
                if (i === 0) {
                    newTxt.push(t)
                } else {
                    if (t === '*' && t_1 !== '*' || t !== '*') {
                        newTxt.push(t)
                    }
                }
            }
            return newTxt.join('')
        },

        replaceEmojiChar(text) {
            const regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
            return text.replace(regex, '');
        },

        abbreviateNumber(number) {
            const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];
            var tier = Math.log10(Math.abs(number)) / 3 | 0;
            if (tier == 0) return number;
            var suffix = SI_SYMBOL[tier];
            var scale = Math.pow(10, tier * 3);
            var scaled = number / scale;
            return scaled.toFixed(1) + suffix;
        },

        checkActiveRoute(name) {
            return name === this.$route.name
        },

        defaultDataDate({ startDate, endDate, warehouses }) {
            let minDate = null
            if (warehouses && warehouses.length === 1) {
                const warehouse = warehouses[0]
                if (warehouse.data_from) {
                    minDate = moment(warehouse.data_from).format('YYYY-MM-DD')
                    startDate = startDate < minDate ? minDate : startDate
                    endDate = endDate < minDate ? minDate : endDate
                }
            }
            return {
                startDate, endDate, minDate
            }
        }
    }
}
